









import { Component, Vue } from 'vue-property-decorator'
import UsersTable from '@/components/admin/UsersTable.vue'
import { AdminUsersDataSource } from '@/api/datasource/admin/adminUsers'
import { IDatasource } from 'ag-grid-community'
import FakeUserAPI from '@/components/admin/fake-auth/helpers/requests'

@Component({
  components: { UsersTable }
})
export default class FakeAuth extends Vue {
  private dataSource: IDatasource = new AdminUsersDataSource()

  private onDoubleClick(node: any) {
    const userId = node.data.id
    FakeUserAPI.fakeUserLogin(userId, node.data.roles)
  }
}
