




import { Component, Vue } from 'vue-property-decorator'
import FakeAuth from '@/components/admin/fake-auth/index.vue'

@Component({ components: { FakeAuth } })
export default class FakeAuthRoute extends Vue {}
